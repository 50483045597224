import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import lodash from 'lodash';
import {
  faAsterisk,
  faHandFist,
  faVirus,
  faFlask,
  faArrowUp,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { IWWCharacter } from '../../modules/common/model/graphql-types';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { WWCharacter } from '../../modules/ww/common/components/ww-character';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';

interface IWWCharacterNodes {
  nodes: IWWCharacter[];
}

interface IWWCharacterEntry {
  allCharacters: IWWCharacterNodes;
}

interface IProps {
  data: IWWCharacterEntry;
}

const WWTierList: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '4★', value: '4' },
          { label: '5★', value: '5' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Aero',
            tooltip: 'Aero',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_aero.png"
                width={24}
                alt="Aero"
              />
            )
          },
          {
            value: 'Electro',
            tooltip: 'Electro',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_electro.png"
                width={24}
                alt="Electro"
              />
            )
          },
          {
            value: 'Fusion',
            tooltip: 'Fusion',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_fusion.png"
                width={24}
                alt="Fusion"
              />
            )
          },
          {
            value: 'Glacio',
            tooltip: 'Glacio',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_glacio.png"
                width={24}
                alt="Glacio"
              />
            )
          },
          {
            value: 'Havoc',
            tooltip: 'Havoc',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_havoc.png"
                width={24}
                alt="Havoc"
              />
            )
          },
          {
            value: 'Spectro',
            tooltip: 'Spectro',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_spectro.png"
                width={24}
                alt="Spectro"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Broadblade',
            tooltip: 'Broadblade',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_broadblade.png"
                width={24}
                alt="Broadblade"
              />
            )
          },
          {
            value: 'Gauntlets',
            tooltip: 'Gauntlets',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_gauntlets.png"
                width={24}
                alt="Gauntlets"
              />
            )
          },
          {
            value: 'Pistols',
            tooltip: 'Pistols',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_pistols.png"
                width={24}
                alt="Pistols"
              />
            )
          },
          {
            value: 'Rectifier',
            tooltip: 'Rectifier',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_rectifier.png"
                width={24}
                alt="Rectifier"
              />
            )
          },
          {
            value: 'Sword',
            tooltip: 'Sword',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_sword.png"
                width={24}
                alt="Sword"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [{ value: 'tower', label: 'Tower' }];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout
      className={'generic-page wuthering ww-tier-page'}
      game="ww"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ww/categories/category_tier.jpg"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Tier List</h1>
          <h2>
            The best tier list for Wuthering Waves that rates all available
            characters by their performance in the Tower of Adversity. Find the
            best characters in the game!
          </h2>
          <p>
            Last updated: <strong>12/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      {/* <div className="afk-ad-banner">
        <div className="cta">
          <h4>New Season "Waves of Intrigue" is now live in AFK Journey</h4>
          <p>Unveil the mysteries of Rustport and uncover new adventures!</p>
          <OutboundLink href="https://bit.ly/Prydwen_WW" target="_blank">
            <Button variant="primary">Download Now</Button>
          </OutboundLink>
        </div>
      </div> */}
      <div className="tier-list-page">
        <SectionHeader title="Wuthering Waves Tier list (1.4 Patch)" />
        <p className="intro">
          Please keep in mind Wuthering Waves is a{' '}
          <strong>
            skill-based game and each character has a different playstyle
          </strong>
          , difficulty level and even special tricks and combos. Wuthering Waves
          is also a team based game and it’s only by combining both these
          factors to the highest level a character can reach optimal
          performance. Our Wuthering Waves tier list was created with the help
          of various players who shared their thoughts about Wuthering Waves
          characters and their performance with us. Special thanks to{' '}
          <strong>
            <a
              href="https://www.youtube.com/@IWinToLose/"
              target="_blank"
              rel="noreferrer"
            >
              IWinToLose
            </a>
          </strong>
          , and{' '}
          <strong>
            <a
              href="https://www.youtube.com/@iamrivenous"
              target="_blank"
              rel="noreferrer"
            >
              iamrivenous
            </a>
          </strong>
          . If you want to learn more about the characters and why we rated them
          in that way, check their profiles -{' '}
          <strong>
            in the Review tab we have posted short reviews and pros & cons.
          </strong>
        </p>
        <Accordion className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the Tier List</Accordion.Header>
            <Accordion.Body>
              <h6>Tower of Adversity</h6>
              <p>
                This tier list assesses characters based on their combat
                potential in{' '}
                <strong>
                  Wuthering Waves current most demanding content Tower of
                  Adversity (ToA)
                </strong>
                . ToA is a mode similar to Genshin Impact’s Spiral Abyss or
                Honkai Star Rail’s Memory of Chaos and requires multiple teams,
                strong Echoes and good Weapons. This mode offers a reasonable
                mix of Boss encounters (single target), Elite packs (2-3
                targets) and AoE scenarios (5+ Targets), allowing multiple
                characters to find a niche but in the end, there's a bias toward
                single target focused characters at the top-end.
              </p>
              <h6>ToA Vs Holograms</h6>
              <p>
                ToA is not the most mechanically demanding content in Wuthering
                Waves with high difficulty Holograms requiring much higher
                player skill levels. Holograms though can only offer their
                rewards once, can be completed with a singular unit, punish
                specific character kits and are exclusively single target. For
                these reasons we feel Tower of Adversity is a better
                representation of an ongoing meta in Wuthering Waves. As
                additional modes or rewards are added we will update the tier
                list or add variants as needed.
              </p>
              <h6>Skill Ceilings</h6>
              <p>
                As an action-combat game, Wuthering Waves offers many combat
                options to players - some of which are not easily executed but
                boost certain characters' powers substantially.{' '}
                <strong>
                  The Switch Cancel is an example of such a technique
                </strong>{' '}
                allowing you to switch out a character mid-attack to a new
                character while still fully executing the move you interrupted,
                essentially allowing you to attack with two characters at once.
                Such techniques won't be for everyone but a tier list must
                assess characters on their maximum potential and best teams.
                Each character is assessed with only the easiest and most
                powerful switch cancels included like Encore Rupture. Harder or
                more frequent cancels, such as Calcharo’s Death Messenger
                cancels, are not considered.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Criteria and roles</Accordion.Header>
            <Accordion.Body>
              <h6>Criteria</h6>
              <ul>
                <li>
                  All characters are assumed to be utilized in their full best 3
                  man team, played with optimal rotations without mistakes,
                  using best Echo main sets and correct Energy Regen values.
                </li>
                <li>
                  All characters are using maximum level gold rarity Echoes,
                </li>
                <li>
                  All Echo main stats are considered to be best in slot for that
                  characters build,
                </li>
                <li>
                  Each Echo used by characters is assumed to have 5 sub-stats
                  with average rolls,
                </li>
                <li>
                  Half of all sub stats (12) are designated as “good” sub stats
                  the character would desire where the remaining (13) are
                  random. This represent good but not insane endgame gear.
                </li>
                <li>
                  5★ characters are all rated at Sequence 0 unless otherwise
                  stated such as the Spectro (Rover) who starts at Sequence 6.
                  4★ characters at rated at Sequence 6.
                </li>
                <li>
                  All characters are assessed as if they have access to S1 of
                  all 4★ Weapons, S5 of all 2★ and 3★ Weapons and S1 of the
                  standard 5★ Weapons everyone will get their free choice of.
                  The best out of all these choices is used for their
                  assessment.
                </li>
                <li>
                  All characters are considered to be the maximum possible level
                  with all Fortes upgraded to maximum rank with a maximum level
                  Weapon.
                </li>
              </ul>
              <h6>Roles</h6>
              <p>
                We compare characters within their role - so DPS are compared to
                other DPS and the ratings are adjusted based on their
                performance within the role. Don't try to compare characters
                between roles, because their ratings mean different things.
              </p>
              <p>
                <strong className="dps">DPS</strong>{' '}
              </p>
              <p>
                DPS characters focus on being your sole damage dealers and the
                rest of your team is built to synergize with them and make them
                stronger. Stacking Echo set bonuses, character kit's and Outro
                Deepen bonuses together to power up a DPS is the goal of many
                teams. DPS character generally aim to spend roughly 14-15
                seconds on the field to consume all the team's buffs.
              </p>
              <p>Criteria that impact ratings for DPS:</p>
              <ul>
                <li>
                  Characters rotation difficulty and potential for mistakes,
                </li>
                <li>Character damage within best possible team,</li>
                <li>
                  Characters on-field flexibility and durability (can they
                  easily dodge during combo, will they die in 1 hit?),
                </li>
                <li>
                  Character ability to deal with all damage types - Single
                  target, Cleave and AoE but with a bias toward single target
                  being valued more.
                </li>
              </ul>
              <p>
                <strong className="specialist">Hybrid</strong>
              </p>
              <p>
                Hybrid characters directly support your damage dealer of choice,
                often with incredibly synergistic kits, specialized Outro buffs
                like “Deepen” multipliers or other beneficial effects. Almost
                always built to complete their rotation right before your DPS
                performs their full damage combo. Hybrid characters kits offer a
                diverse combination of supportive, offensive and sustaining
                capabilities and possess the most variety in gameplay and have
                many different niches. Most Hybrid characters prioritize
                generating their Concerto, Resonance energy, and getting their
                buffs up in as little on-field time as possible to maximize the
                field time of the damage dealer.
              </p>
              <p>Criteria that impact ratings for Hybrid:</p>
              <ul>
                <li>
                  Characters rotation difficulty and potential for mistakes,
                </li>
                <li>Character performance within best possible team,</li>
                <li>How many effective teams character is playable in,</li>
                <li>
                  Impact of kit on a teams performance (units that carry a team
                  rank higher),
                </li>
                <li>
                  Characters on-field flexibility and durability (can they
                  easily dodge during combo, will they die in 1 hit?),
                </li>
                <li>
                  Characters effective damage contribution after including their
                  buffs on the team,
                </li>
                <li>Total on-field time required to perform rotation.</li>
              </ul>
              <p>
                <strong className="amplifier">Support</strong>
              </p>
              <p>
                Support characters must have the capability to provide benefit
                to the team regardless of the order in which they are switched
                in, so as not to conflict with the majority of Hybrid characters
                outro’s only buffing the next character to enter. Supports aim
                to provide maximum buffs, utility, defenses and healing in as
                little field time as possible to maximize the capabilities of
                your damage dealer foremost but also your hybrid character.
              </p>
              <p>Criteria that impact ratings for Supports:</p>
              <ul>
                <li>
                  Characters rotation difficulty and potential for mistakes,
                </li>
                <li>Character performance within best possible teams,</li>
                <li>How many effective teams character is playable in,</li>
                <li>
                  Impact of kit on a teams performance (units that carry a team
                  rank higher),
                </li>
                <li>
                  Characters on-field flexibility and durability (can they
                  easily dodge during combo, will they die in 1 hit?),
                </li>
                <li>
                  Characters effective damage contribution after including their
                  buffs on the team,
                </li>
                <li>Total on-field time required to perform rotation.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Tags</Accordion.Header>
            <Accordion.Body>
              <h6>Special tags</h6>
              <p>
                Tags represent the most defining features of a character's kit.
              </p>
              <ul>
                <li>
                  <strong className="archetype">ST</strong> - Character deals
                  mostly single target damage to enemies but can hit multiple
                  enemies if they are stacked directly on top of each other.
                </li>
                <li>
                  <strong className="archetype">Cleave</strong> - Character has
                  a mix of smaller range cleaving attacks or attacks the strike
                  in a radius around them but does not consistently hit all
                  enemies in the screen with the majority of their rotation.
                </li>
                <li>
                  <strong className="archetype">AoE</strong> - A large portion
                  of these characters damage or benefit is in the form of pure
                  AoE hitting most if not all enemies on the battlefield.
                </li>
                <li>
                  <strong className="pro">Heal</strong> - Character can heal
                  themselves or the team, providing sustain but also triggering
                  the 5-P Rejuvenating Glow set bonus.
                </li>
                <li>
                  <strong className="pro">Shield</strong> - Character can apply
                  shields to themselves or the entire team and/or provide
                  defensive buffs and benefits further increasing the
                  survivability.
                </li>
                <li>
                  <strong className="pro">Coord</strong> - Character can perform
                  Coordinated Attacks consistently, thanks to a major portion of
                  their kit providing damaging, supporting or utility benefits
                  while not on the field. Or the character can make use of
                  Coordinated Attacks to enhance their own kit.
                </li>
                <li>
                  <strong className="pro">Multirole</strong> - When built in an
                  alternative way, can move to a different category on the tier
                  list and flex their role to another position even if it’s less
                  conventional.
                </li>
                <li>
                  <strong className="cons">Cancel</strong> - Character needs or
                  is widely considered to be far more powerful with the use of
                  “switch canceling” to enhance their performance.
                </li>
                <li>
                  <strong className="cons">Ult</strong> - Character is
                  ultimate-centric with most of their damage occurring during an
                  ultimate window, creating a reliance on it. Without the
                  ultimate active character feels lackluster.
                </li>
                {/* <li>
                  <strong className="cons">Hard</strong> - Characters rotation
                  has very strict damage windows, tricky to land combos or
                  sequences or both. Meaning this character can move higher on
                  the tier list in the hands of an expert or even lower in the
                  hands of a novice.
                </li> */}
                <li>
                  <strong className="cons">Risk</strong> - Character that throws
                  safety to the wind and treads the line of death with
                  dramatically lower defensive capabilities than other
                  characters. You will die... a lot if you don’t play perfectly.
                </li>
                <li>
                  <strong className="special">Expert</strong> - Characters
                  tagged with the Expert Tag can be considered one placement
                  higher than their tier list ranking, but you need to master
                  their advanced/harder playstyle for them to qualify for that
                  higher rating.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Changelog
              <span className="tag new">Updated!</span>
            </Accordion.Header>
            <Accordion.Body>
              <h6>12/12/2024</h6>
              <p>List of changes:</p>
              <ul>
                <li>
                  <strong>Lumi</strong> has been added to the tier list,
                </li>
                <li>Added new tags to multiple characters:</li>
                <ul>
                  <li>
                    <li>
                      Added Expert tag to <strong>Yangyang</strong>,
                    </li>
                    <li>
                      Added Multirole tag to <strong>Aalto and Danjin</strong>,
                    </li>
                    <li>
                      Added Coord tag to <strong>Baizhi</strong>,
                    </li>
                    <li>
                      Added Heal tag to <strong>Rover (Spectro)</strong>,
                    </li>
                  </li>
                </ul>
                <li>
                  Moved <strong>Rover (Spectro)</strong> to the Support
                  category.
                </li>
              </ul>
              <h6>03/12/2024</h6>
              <p>
                In this tier list update we’re adjusting a number of character
                categories to reflect their most competitively played role in
                endgame with S6 in mind. We’re also expanding the selection of
                characters marked with the Expert tag and removing the Risk tag
                as the newer Expert tag makes it redundant. Thank you for all
                the feedback send our way, especially{' '}
                <a
                  href="https://www.youtube.com/@e-thin"
                  target="_blank"
                  rel="noreferrer"
                >
                  E-Thin
                </a>
                ,{' '}
                <a
                  href="https://www.youtube.com/@GachaSmack07"
                  target="_blank"
                  rel="noreferrer"
                >
                  Gacha Smack
                </a>
                , Serra, Sweat and Thundah.
              </p>
              <ul>
                <li>
                  <strong>Danjin</strong>: Danjin has value both thanks to her
                  damage output and buffs at S6, so we’re moving her to the
                  Hybrid category to better reflect her not simply being a pure
                  damage dealer. Also as mentioned above we’re exchanging her
                  Risk tag for the Expert tag instead.
                </li>
                <li>
                  <strong>Changli</strong>: We’re adding the Expert tag to
                  Changli in recognition of her more challenging playstyle when
                  compared to other characters. As a result we’re lowering her
                  placement half a tier for the average player but as per the
                  Expert tag for those who can play her optimally her ranking
                  remains unchanged - basically Changli floor is already at a
                  very decent level, but her ceiling can only be reached if you
                  know what to do.
                </li>
                <li>
                  <strong>Yangyang & Jianxin</strong>: With S6 (for Yangyang)
                  and new teams in mind, Jianxin and Yangyang are moving into
                  the Hybrid category to better reflect their position in the
                  compositions and playstyles of the current meta.
                </li>
                <ul>
                  <li>
                    Yangyang calculations and rotations will be updated soon to
                    reflect that.
                  </li>
                </ul>
                <li>
                  <strong>Aalto</strong>: Following up on our previous update
                  we’re moving forward with shifting Aalto into the Damage
                  category. As a Hybrid he currently has no team - at best he is
                  a backup for Jiyan if Mortefi isn’t an option. At S6 he has an
                  identity as a damage dealer so we’re moving him there for now.
                  Though, he may find himself returning to Hybrid with the
                  release of new Aero Damage dealers.
                </li>
                <li>
                  <strong>Yuanwu & Spectro Rover</strong>: With Yangyang and
                  Jianxian moving into the Hybrid category, we’re adjusting the
                  rank of Yuanwu and Spectro Rover accordingly. Neither Yuanwu
                  nor Spectro Rover have high meta relevance - Yuanwu has a
                  niche position in Jinhsi teams but sits below many other
                  better alternatives. And Spectro Rover sees little use due to
                  their overlap with the far superior Havoc Rover. For these
                  reasons we’re positioning them a tier below the newly moved
                  Yangyang and Jianxin for now.
                </li>
                <li>
                  <strong>Mortefi & Zhezhi</strong>: We’re aware some players
                  feel Mortefi and Zhezhi are rated too highly based on the fact
                  they’re “One Team Wonders” - only really serving as heavy
                  hitters in Jiyan and Jinhsi teams respectively. Despite this
                  we feel the teams these characters sit within are strong
                  enough to justify holding onto their rating for now, but with
                  that in mind we’ll be keeping an eye on them moving into 2.0.
                </li>
              </ul>
              <h6>18/11/2024</h6>
              <p>
                In our previous update we asked for your feedback on a few
                characters and based on that we’re making some additional
                updates to the tier list:
              </p>
              <ul>
                <li>
                  <strong>Camellya</strong> T0.5
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0. After a few more days of testing, we’re updating her
                  rating from Tier 0.5 to Tier 0. When measuring Camellya’s kit
                  and numbers against other top tier characters, she isn’t a
                  step above her competition - she’s quite similar (in a
                  vacuum). However, Camellya (and her teams), unlike a lot of
                  other characters is quite accessible, making achieving her
                  full potential not only possible but fairly easy. Based on
                  this, Camellya ends up performing better than her competition
                  in T0.5 and closer to what Jinhsi offers - because of that
                  we’re raising her to Tier 0 alongside her. Also, a different
                  rotation was found that improves her single target damage
                  output and we're currently testing it - once we're done, the
                  calcs on site will be updated. Stay tuned!
                </li>
                <li>
                  <strong>Changli</strong> T0.5
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0 and moved to Hybrid category. We’ve gathered and evaluated
                  all your feedback on which category the community feels
                  Changli should be placed in. As a result we’ve altered her
                  category from Main DPS to Hybrid whilst also raising her into
                  Tier 0 to better fit her position in the meta relative to
                  other characters in this category. We’ll continue to monitor
                  how Changli is being played in future patches and with
                  additional character releases as to whether she’ll make a
                  return to the Main DPS category.
                </li>
                <li>
                  <strong>Yinlin</strong> T0
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T0.5. Yinlin is a character we categorized on the edge of Tier
                  0 and Tier 0.5 at the time of our rework. She's able to be
                  played in many of the top ToA teams with ease, but always with
                  options for substitution (Xiangli Yao, Jinhsi, etc). With the
                  move of Changli to Hybrid Tier 0 we’re moving her from one
                  edge to another and placing her into Tier 0.5 for now.
                </li>
              </ul>
              <h6>14/11/2024</h6>
              <ul>
                <li>
                  <strong>Camellya</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                As promised in the last update, we have revamped the tier list
                with the addition of 4★ characters set at Sequence 6. For some
                4★ the update to Sequence 6 is a substantial one - for others it
                has less impact. Some 4★ characters have moved up in the Tier
                List while others positions have only improved relative to other
                5★ characters with a similar role. Also this update is a general
                parse on the meta resulting in some characters moving position
                regardless of additional Sequences.
              </p>
              <p>
                Additionally as a part of this update we’re also introducing a
                new Tag alongside removing some old ones to address the ranking
                of specific characters we’ve had trouble placing in the past.
              </p>
              <p>
                Our Tier Lists aims to reflect not only in-game testing and
                performance but also to ideally align with community opinion and
                experience. If you have any feedback on the updated Tier List
                don’t hesitate to reach out and share - we’re always listening
                and ready to make changes as needed!
              </p>
              <p>
                <strong>The Expert Tag:</strong> Internally we set an
                expectation of what we consider a reasonable level of play when
                writing guides and assessing characters performance. Sometimes
                characters' optimal play styles fall outside these expectations,
                resulting in a lower than expected placement on our Tier List
                but with evidence of extraordinary performance from
                dedicated/skilled community members.{' '}
                <strong>
                  Our goal is to represent the value of a character to the
                  average player but we also agree a character's maximum power
                  must be recognized.
                </strong>{' '}
                The Expert Tag is our solution to this. Characters tagged with
                the Expert Tag can be considered one placement higher than their
                tier list ranking - we’ve attempted something similar in the
                past but we’re now making it far more obvious by branding the
                character's portrait with a special icon representing this
                higher placement. We hope dedicated players of these characters
                understand the lower placement, but with the recognition of
                potential with mastery of the character.
              </p>
              <p>
                <strong>[Encore & Calcharo]</strong> Encore and Calcharo are the
                two characters at the time of rework we are attaching the Expert
                Tag to. Encore in the hands of most players is a solid Resonator
                with good Single Target and Cleave damage output boasting a
                relatively simple playstyle and rotation. However, in the hands
                of the dedicated Encore players, she becomes far more - she
                becomes highly technical, far harder to play, but also much
                stronger. She is the driving force behind the introduction of
                this special tag. Calcharo, similar to Encore, has a baseline
                performance much lower than his maximum potential and for all
                the same reasons we’re attaching the tag to him as well.
              </p>
              <p>
                When reworking the Tier List two characters were the subject of
                much discussion, Changli and Aalto (S6). As a result of these
                discussions we’re placing both characters on our Watch List with
                the intention of hearing what you have to say on these
                characters before proceeding with any changes.
              </p>
              <ul>
                <li>
                  <strong>[Changli]</strong> Compared to Camellya, Xiangli Yao
                  and Jiyan, Changli doesn’t feel placed quite right. She has no
                  meta defining “best” team when compared to these characters at
                  this time and usually makes do with a non-perfect fit, hybrid
                  character, double DPS Quick-Swap set-up or double Support
                  team. Changli has fantastic potential but feels limited by
                  teammate choices - she sometimes serves as a Hybrid in the
                  Sub-DPS role and doesn’t feel like she has quite the meta
                  foothold the aforementioned 3 have. We are also considering
                  rating her as both Main DPS and Hybrid and she would then
                  appear in both columns with different ratings - do let us know
                  what you think of the idea.
                </li>
                <li>
                  <strong>[Aalto]</strong> Aalto currently has no place in any
                  team as a supportive character. Our only Aero damage character
                  is Jiyan and Mortefi has proven to be the superior choice in
                  his team. Until an Aero character for Aalto to support is
                  released we expect him to continue to struggle as a Hybrid
                  Support/DPS character. At S6 Aalto gains substantial damage
                  potential with many considering him to be more of a main DPS.
                  Our team is currently considering moving Aalto to the Pure DPS
                  category until such a time a character he can support is
                  released.
                </li>
              </ul>
              <h6>24/10/2024</h6>
              <ul>
                <li>
                  <strong>Youhu</strong> has been added to the tier list.
                </li>
                <li>
                  4★ will be upgraded to S6 with the 1.4 big patch, so in 3
                  weeks.
                </li>
              </ul>
              <h6>29/09/2024</h6>
              <ul>
                <li>
                  <strong>The Shorekeeper</strong> has been added to the tier
                  list.
                </li>
              </ul>
              <h6>07/09/2024</h6>
              <ul>
                <li>
                  <strong>Xiangli Yao</strong> has been added to the tier list.
                </li>
                <li>
                  <strong>Yuanwu</strong> T1
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1.5. Yuanwu gained considerable ground in the meta on the
                  release of Jinhsi and was one of the go to team members for
                  her especially for F2P players. Since then we’ve seen the
                  release of multiple characters viable with Jinhsi and a
                  progression of the team building meta around her. With these
                  new options available or discovered Yuanwu has lost some of
                  what gave him his meta relevance, he is still usable with
                  Jinhsi but we’re lowering him down to Tier 1.5 to reflect his
                  new place among all her new options.
                </li>
                <li>
                  <strong>Jianxin</strong> T1
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1.5. As Tower of Adversity’s difficulty continues to increase
                  a greater emphasis is being placed on slaying progressively
                  harder boss type enemies as opposed to smaller packs of
                  enemies. As a result of this trend we’ve seen demand for
                  Jianxia’s AoE control abilities fall leading to a drop in
                  popularity and meta relevance compared to her most similarly
                  rated peer Baizhi. Because of this we’re adjusting Jianxia’s
                  tier down to 1.5 for now.
                </li>
              </ul>
              <h6>15/08/2024</h6>
              <ul>
                <li>
                  <strong>Zhezhi</strong> has been added to the tier list.
                </li>
                <li>
                  The release of Zhezhi offers new team building options to a
                  number of characters currently underutilized by much of the
                  community (for example Lingyang). Outside of the Zhezhi
                  placement on the current tier list we're also actively
                  monitoring the impact she has on the performance of the
                  aforementioned lesser utilized characters to see if some
                  adjustments to the list are required.
                </li>
              </ul>
              <h6>25/07/2024</h6>
              <ul>
                <li>
                  <strong>Encore</strong> T1
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5.
                </li>
                <ul>
                  <li>
                    Encore is a character with significant potential for skill
                    expression, offering both an easy-to-use and reasonably
                    strong damaging rotation, as well as a devastatingly potent
                    advanced rotation that incorporates Dash Cancels, Swap
                    Backs, and more. The damage difference between the simple
                    Encore rotation and the advanced one, which utilizes all
                    available tricks, is staggering.
                  </li>
                  <li>
                    When evaluating our Tier List, we strive to consider both
                    the difficulty and potential of all characters and rank them
                    accordingly. In Encore’s case, the community feels that her
                    high skill ceiling playstyle is currently underappreciated
                    in her rating. We agree with this assessment and will be
                    raising her to tier 0.5. When played to her full potential,
                    Encore is capable of some of the highest Single-Target
                    damage in the game.
                  </li>
                  <li>
                    Representing difficulty on our Tier List is a challenge. We
                    recently dropped Calcharo due to his overly complex baseline
                    rotation. Encore, however, is different. Unlike Calcharo,
                    her baseline damage is easy to access and strong, and she
                    has an advanced, competitive rotation on top of this, which
                    justifies raising her tier.
                  </li>
                </ul>
              </ul>
              <h6>22/07/2024</h6>
              <ul>
                <li>
                  Our team feels enough time has passed after the release of the
                  game to{' '}
                  <strong>
                    raise all 4 stars baseline on the tier list to Sequence 2
                  </strong>
                  . Part of this decision is influenced by the large quantity of
                  pulls and premium currency Kuro has distributed, allowing even
                  F2P players to amass multiple copies of many 4 stars. All 4
                  stars' position on the tier list has been reevaluated even if
                  their ranking has not changed. In some time we will also add a
                  toggle to switch between S0 and S2, but we simply weren't able
                  to introduce it in time for Changli's release.
                </li>
                <li>
                  We feel many of the damage dealers in Wuthering have their
                  place within the endgame meta and are absolutely viable. In
                  order to ensure we can demonstrate the closeness of each
                  characters' performance we have added an additional tier to
                  the tier list - <strong>Tier 1.5</strong>.
                </li>
                <li>
                  <strong>Changli</strong> has been added to the tier list. Her
                  review, pros and cons that justify the ratings will be
                  available on her profile soon.
                </li>
                <li>
                  <strong>Jinhsi</strong> has proven herself to be far more
                  powerful as we’ve learnt more about her, her team compositions
                  and how to make use of her unique kit to overwhelm and
                  invalidate many of the bosses in the game. Jinhsi convincingly
                  has some of, if not the highest damage, teams at Sequence 0,
                  making her the top damage dealer for Tower of Adversary. On
                  top of this, Jinhsi’s airborne state has proven to be
                  absolutely oppressive in the face of most endgame bosses,
                  allowing her to attack with little need to dodge or suffer
                  interruptions. For all these reasons and more, we’re
                  re-adjusting the DPS column resulting in many characters
                  moving down half a tier compared to Jinhsi.
                </li>
                <li>
                  <strong>Jiyan</strong> T0
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T0.5. Jiyan has good damage, grouping and AoE but lacks the
                  defensive capabilities and raw output Jinhsi and her teams can
                  provide currently. After consulting with the community and
                  extended testing we feel it appropriate to move Jiyan half a
                  tier below Jinhsi in the current state of endgame.
                </li>
                <li>
                  <strong>Rover (Havoc) & Encore</strong> T0.5
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1. With Jiyan’s move to Tier 0.5, we’re moving Havoc Rover
                  and Encore down half a tier to maintain the status quo as we
                  still feel each of them are behind him in power.
                </li>
                <li>
                  <strong>Calcharo</strong> T0.5
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1.5. Unlike Havoc Rover and Encore, we’re moving Calcharo
                  down a full tier instead of only half a tier. Calcharo has
                  potential to deal top tier damage, contending with the likes
                  of Jinhsi and Jiyan’s top teams in the hands of a swap cancel,
                  combo, bossing, no lag gaming god. But most players do not
                  fall within this category as Calcharo is notoriously hard to
                  play at even a reasonable level. In many cases, due to his
                  long trick filled combos, reliance on Swap Canceling and
                  punishing Ultimate duration, he falls below expectations,
                  resulting in lower damage output than Encore or Havoc Rover.
                  We acknowledge Calcharo can compete with the best, but he is
                  held back by the severe difficulty required to actually use
                  him to his full potential.
                </li>
                <li>
                  <strong>Danjin</strong> T1
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1.5. In line with the changes to Havoc Rover and Encore,
                  Danjin is moving down half a tier to facilitate the
                  re-shuffling of the damage category. Her relative placement
                  (outside of Jinhsi) remains unchanged.
                </li>
                <li>
                  <strong>Jianxin</strong> T0.5
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1. As players are reaching the point they can confidently
                  fully clear ToA with close to or maximum stars Jianxin’s value
                  is starting to drop. Her biggest strength lies in grouping
                  enemies and the majority of the hardest fights players are
                  struggling with, have limited or no enemies for her to group.
                  The stages she previously excelled at have become much easier
                  due to players account levels being higher and them
                  over-leveling most of the AoE content in the tower.
                </li>
              </ul>
              <h6>28/06/2024</h6>
              <ul>
                <li>
                  <strong>Jinhsi</strong> has been added to the tier list.
                </li>
                <ul>
                  <li>
                    Jinhsi is incredibly easy to play, has very few weaknesses
                    in her kit and does phenomenal cleave damage, giving every
                    other damage dealer a run for their money. Despite her
                    reliance on coordinated attacks, Jinhsi has suitable options
                    when choosing her teammates for both spenders and F2P
                    players as well. For these reasons we’re placing her in Tier
                    0 alongside Jiyan, but we’ll be keeping an eye on Jinhsi and
                    Jiyan’s performance moving forward to see if either of them
                    can edge each other out in further testing and in the eyes
                    of the community.
                  </li>
                </ul>
                <li>
                  <strong>Yuanwu</strong> T2
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T1.
                </li>
                <ul>
                  <li>
                    Yuanwu finally has a place to call home and that’s side by
                    side with Jinhsi. Yuanwu isn’t necessarily her best
                    coordinated attack teammate but he definitely gets the job
                    done even at S0. As Yuanwu now has very real meta relevance
                    we’re moving him up to Tier 1 and will keep an eye on his
                    performance as the community has more time to test him with
                    Jinhsi.
                  </li>
                </ul>
                <li>
                  <strong>Spectro Rover</strong> T1
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T2.
                </li>
                <ul>
                  <li>
                    Spectro Rover certainly isn’t bad but suffers from the fact
                    they directly conflict with a superior option - Havoc Rover.
                    Only being able to use one or the other, with most people
                    choosing Havoc is a massive demerit for this character who
                    currently doesn’t have any known team’s they’re strictly the
                    best in. On top of this we feel Yuanwu has more meta
                    relevance than Spectro Rover so keeping them together in the
                    same tier doesn’t feel right. For these reasons we’re moving
                    Spectro Rover down a tier.
                  </li>
                </ul>
              </ul>
              <h6>17/06/2024</h6>
              <ul>
                <li>
                  <strong>Yangyang</strong> T1
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T2 - Yangyang's performance in endgame has proven to be more
                  limited than expected when compared to alternatives, for this
                  reason we're lowing her from Tier 1 to Tier 2. Yangyang's
                  gathering and crowd control abilities, while useful, are not
                  on the level of a character such as Jianxin, who is able to
                  clump up entire floors of enemies in Tower of Adversary to be
                  bursted down with AoE. Yangyang's gathering is limited to 2-3
                  enemies in the best of cases, and none at worst, making it a
                  nice bonus addition to her skill, but a significant downgrade
                  compared to the floor-destroying force Jianxin provides.
                  Yangyang's Outro has also proven to be less powerful than her
                  piers. Granting energy is certainly a handy bonus, but one
                  that can be made up for with sufficient energy regeneration.
                  Damage amplification Outros such as Baizhi's and Verina's can
                  not be compensated for with gear and can be the deciding
                  factor in clearing a floor with 3 stars or not. Due to these
                  shortcomings, Yangyang falls behind alternatives like Baizhi,
                  resulting in her moving down a tier.
                </li>
              </ul>
              <h6>06/06/2024</h6>
              <ul>
                <li>
                  <strong>Yinlin</strong> has been added to the tier list.
                </li>
              </ul>
              <h6>31/05/2024</h6>
              <p>
                <strong>
                  Jianxin has been moved to Support category and her rating has
                  been increased from T1 to T0.5
                </strong>
                . After discussing, reviewing and testing different playstyles
                put forth by the community, creators and our own team, Jianxin
                has found great success in the Support role thanks to her CC,
                defensive nature, gathering and situationally powerful Outro. As
                big Jianxin fans, we’re very happy she’s able to shine in the
                AoE portions of ToA. For more info about her, check our{' '}
                <a
                  href="https://blog.prydwen.gg/2024/05/31/wuthering-waves-meta-update/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  blog here
                </a>
                .
              </p>
              <h6>27/05/2024</h6>
              <ul>
                <li>
                  <strong>Rover (Havoc)</strong> T1
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5
                </li>
                <ul>
                  <li>
                    The Havoc Rover is as an outstanding character, fully
                    capable of contending in endgame Tower of Adversity. Since
                    their addition to the tier list, multiple new ways to play
                    them and build their teams have been discovered - with the
                    most powerful being the double Dreamless burst rotation team
                    consisting of Havoc Rover (Dreamless), Danjin (Dreamless)
                    and any support of your choosing most commonly Baizhi,
                    Verina or Jianxin.
                  </li>
                  <li>
                    It is no exaggeration to call this team one of the best in
                    the game and we fully expect it to be a staple in a large
                    amount of full clears of the initial Tower. That's why we're
                    raising Havoc Rover to Tier 0.5 for now, but will be keeping
                    an eye on their performance and any new developments that
                    might raise them even further.
                  </li>
                  <li>
                    Below we've linked two videos - one showcasing the power of
                    the Double Dreamless teams and the other showing some
                    rotations for the Rover.
                  </li>
                </ul>
              </ul>
              <Row className="video-row">
                <Col xs={12} lg={4}>
                  <YoutubeEmbed embedId="T0IfPDR0wmM" />
                </Col>
                <Col xs={12} lg={4}>
                  <YoutubeEmbed embedId="UrnsviS6EFw" />
                </Col>
              </Row>
              <h6>25/05/2024</h6>
              <ul>
                <li>
                  <strong>Rover (Havoc)</strong> has been added to the tier
                  list,
                </li>
                <li>
                  <strong>Sanhua</strong> T1
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5 - With the addition of Rover (Havoc) to the tier list,
                  who deals a large chunk of their damage through Basic Attacks,
                  Sanhua finds another team to call home. And even when playing
                  the Burst variant of Havoc Rover Sanhua is still a great
                  choice. This addition means Sanhua is now the best support in
                  Encore, Lingyang and Havoc Rover's teams - all of which are
                  viable in endgame. On top of that, Sanhua also easily slots
                  into other teams as a generic carrier of the 5P Moonlit set
                  and Heron echo, meaning she can extend her usability even
                  beyond this. With some of the most coverage, lowest field
                  time, and effective Concerto rotations, Sanhua joins Mortefi
                  as one of the go-to Hybrid options for most teams.
                </li>
                <li>
                  <strong>Danjin</strong> received the Multirole tag. With the
                  'release' of Rover (Havoc), she can be now also played as
                  Hybrid thanks to her Havoc DMG Deepen Outro.
                </li>
              </ul>
              <h6>23/05/2024</h6>
              <p>
                Our team has reviewed all changes made to each character in 1.0
                against our experiences in pre-release version and made the
                following changes to our initial rankings.
              </p>
              <p>
                <strong>"Deepen Multipliers"</strong>
              </p>
              <p>
                In 1.0 all characters Outro buff skills have been changed from
                standard increases for example Heavy ATK DMG% (Mortefi) or
                Resonance Liberation DMG% (Jianxin) which stacked additively
                with normal increases to DMG% like Electro DMG% or standard DMG%{' '}
                <strong>
                  to a new multiplier that scales outside of them called a
                  "Deepen" multiplier
                </strong>
                . The "Deepen" multiplier is a true multiplier outside of the
                standard damage formula, meaning after all your normal team
                buffs including CRIT RATE, ATK%, DMG%, etc. are included.{' '}
                <strong>
                  For example, if you dealt 1000 damage normally, after a
                  "Deepen" buff of 15% you would now deal 1150.
                </strong>{' '}
                This is a tremendous change and raises the value of specific
                characters with Deepen buffs that are useful to the most
                powerful main damage characters as such tier list adjustments
                are required.
              </p>
              <ul>
                <li>
                  <strong>Jianxin</strong> T2
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T1 - Jiaxin has a Liberation DMG% "Deepen" Outro which is
                  excellent for Calcharo, especially before Yinlin arrives as
                  his premium support raising her value. Additionally thanks to
                  community theorycrafting efforts an alternative way of
                  conducting Jianxin's Concerto Rotation has been discovered
                  offering her a much faster option and much higher potential to
                  be played as a support. This new rotation involves starting
                  and stopping her Forte Gauge Primordial Jade Sprial as fast as
                  possible just to gain the Resonance Energy and Concerto from
                  it. This new rotation loses out on some of her incredibly
                  powerful kit like her shields but drastically reduces her
                  field time making her a much stronger choice as a Hybrid
                  character (which is even better now thanks to "Deepen"). This
                  new option, on top of her old options, and her drastically
                  increased supportive capabilities push her to a higher status
                  as such we're raising her to Tier 1.
                </li>
                <ul>
                  <li>
                    We will soon update Jianxin damage calculations and her
                    rotation on her profile too.
                  </li>
                </ul>
                <li>
                  <strong>Aalto</strong> T3
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T2 - Previously Aalto lacked supportive capabilities due to
                  his Aero DMG% Outro stacking unfavorably with other damage
                  multipliers, but with the changes to Deepen this is no longer
                  the case especially with Jiyan. 23% Aero Deepen is a strong
                  option for one of the strongest characters and absolutely
                  makes him a viable alternative to Mortefi (albeit a little
                  behind in most full team configurations) for those without him
                  or who want to use Mortefi elsewhere.
                </li>
                <ul>
                  <li>
                    While Aalto's buffing capabilities are similar to Mortefi's
                    for Jiyan specifically, he does not have as many
                    applications (Mortefi can be used for Jianxian, Jiyan and
                    Danjin), he is harder to play than Mortefi due to some
                    positioning being required around his Mist Gates, he relies
                    on movement during his rotation (which can be a good or bad
                    thing), takes up more field time and lacks Mortefi's
                    Coordinated Attack ultimate. Aalto is behind Mortefi but
                    offers some interesting evasive and defensive options and is
                    now a real option on a powerful team, for this reason we are
                    raising him a tier.
                  </li>
                </ul>
                <li>
                  <strong>Yangyang</strong> T2
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T1 - With the introduction of "Deepen" creating and exploiting
                  damage windows on DPS characters has become more powerful.
                  Many of the current characters in the game such as Calcharo,
                  Jiyan and Encore all require their Ultimate in order to
                  achieve maximum damage and will aim to have it active at the
                  same time as all team buffs and a powerful "Deepen"
                  multiplier. Yangyang's Outro offers Energy Regen - something
                  that can assist these characters with gaining access to their
                  Ultimates faster in order to exploit that burst window which
                  is now more valuable compared to previous versions of the
                  game. With the rise of Deepen multipliers and most of them
                  being restricted to "next character only", the fact Yangyang
                  has an alternative which works around this, grants her a lot
                  of value in team compositions.
                </li>
                <ul>
                  <li>
                    You can use your DPS ultimate burst window, swap to
                    Yangyang, charge up her Concerto, use her Outro on your main
                    DPS for energy then switch to your Deepen buffer earn the
                    Concerto there and then go straight back into Ultimate Burst
                    that much faster thanks to Yangyang. For these reasons on
                    top of her already great and flexible supportive kit we're
                    raising her up a tier for now.
                  </li>
                </ul>
                <li>
                  <strong>Taoqi</strong>. Like Jianxin and Aalto, Taoqi
                  possesses a "Deepen" multiplier but for Resonance Skill
                  damage. Currently based on our research the only DPS that
                  deals a large amount of Resonance Skill damage is Chixia whose
                  performance isn't a match for higher rated damage dealers. Due
                  to Taoqi's "Deepen" bonus not being immediately applicable in
                  patch 1.0 we're leaving her were she is for now but keeping an
                  eye on her ready to take action if a new character or current
                  character shifts to abusing Resonance Skill damage high up the
                  tiers.
                </li>
                <li>
                  <strong>Yuanwu</strong> received major mechanical changes
                  compared to CBT2 and we're currently retesting him and his
                  placement may change in the future.
                </li>
              </ul>
              <p>
                <strong>Damage Dealer Changes</strong>
              </p>
              <p>
                Many have noted large changes to character balance between CBT2
                and 1.0 for DPS characters like Calcharo and Encore. Please rest
                assured all Prydwen's calculations already have all these nerfs
                factored in and our tier list also accounts for them.
              </p>
              <h6>20/05/2024</h6>
              <p>Initial release of the tier list.</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
        <div className="tier-list-header">
          <h2 className="title">
            Wuthering Waves Tier List <span>(Tower of Adversity)</span>
          </h2>
          <h3 className="sub-title">
            You're currently viewing the Wuthering Waves{' '}
            <strong>Tower of Adversity</strong> tier list. It shows how the
            character performs in the mode and how easy time they will have
            clearing it.
          </h3>
        </div>
        <div className="employees-filter-bar ww">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-ww">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-header debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-header support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
            </div>
          </div>
          <div className="custom-tier tier-0 first">
            <div className="tier-rating t-0">
              <span>T0</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-05">
            <div className="tier-rating t-05">
              <span>T0.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-1">
            <div className="tier-rating t-1">
              <span>T1</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-15">
            <div className="tier-rating t-15">
              <span>T1.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-2">
            <div className="tier-rating t-2">
              <span>T2</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-3">
            <div className="tier-rating t-3">
              <span>T3</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
      </div>
    </DashboardLayout>
  );
};

export default WWTierList;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Wuthering Waves | Prydwen Institute"
    description="The best tier list for Wuthering Waves that rates all available characters by their performance in the Tower of Adversity. Find the best characters in the game!"
    game="ww"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulWwCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        element
        weapon
        tierCategory
        tierTags
        ratings {
          tower
        }
        isNew
      }
    }
  }
`;
